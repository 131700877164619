// Loading.js
import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const Loading = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
      <ArrowPathIcon className="h-12 w-12 text-blue-500 animate-spin" />
    </div>
  );
};

export default Loading;
