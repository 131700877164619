import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { ColDef } from "ag-grid-community";
import { useLoading } from "./shared/LoadingContext";

const FunctionAppsList = () => {
  const { instance, accounts } = useMsal();
  const [rowData, setRowData] = useState([]);
  const { loading, setLoading } = useLoading();
  const [error, setError] = useState<any>(null);

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiScope = "api://utilities/managefunctionapp";

  const columnDefs: ColDef[] = [
    { headerName: "Name", field: "name" },
    { headerName: "Subscription", field: "subscriptionName" },
    { headerName: "Resource Group", field: "resourceGroup" },
    { headerName: "State", field: "state" },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: (params: any) => (
        <div>
          {params.data.state == "Running" ? (
            <button
              onClick={() =>
                handleStartStop(
                  params.data.subscriptionId,
                  params.data.resourceGroup,
                  params.data.name,
                  "stop"
                )
              }
            >
              Stop
            </button>
          ) : (
            <button
              onClick={() =>
                handleStartStop(
                  params.data.subscriptionId,
                  params.data.resourceGroup,
                  params.data.name,
                  "start"
                )
              }
            >
              Start
            </button>
          )}
        </div>
      ),
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const accessToken = await instance.acquireTokenSilent({
        scopes: [apiScope],
        account: accounts[0],
      });

      const response = await axios.get(`${apiUrl}/ManageApps`, {
        headers: {
          Authorization: `Bearer ${accessToken.accessToken}`,
        },
      });
      setRowData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleStartStop = async (
    subscriptionId: string,
    resourceGroup: string,
    name: string,
    action: string
  ) => {
    setLoading(true);
    setError(null);

    try {
      const accessToken = await instance.acquireTokenSilent({
        scopes: [apiScope],
        account: accounts[0],
      });

      await axios.post(
        `${apiUrl}/ManageApps/${subscriptionId}/${resourceGroup}/${name}/${action}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken.accessToken}`,
          },
        }
      );
    } catch (error) {
      console.error("Error starting function app:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [instance, accounts]);

  return (
    <>
      <h2 className="text-lg font-bold mb-2 text-start">
        Resources
        <div className="border-b border-gray-200 w-full mt-2"></div>
      </h2>

      <div className="ag-theme-alpine" style={{ height: 500 }}>
        {error && <div>Error: {error.message}</div>}
        {accounts.length > 0 && instance && (
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={{ sortable: true, filter: true }}
          />
        )}
      </div>
    </>
  );
};

export default FunctionAppsList;
