import { PublicClientApplication } from "@azure/msal-browser";

const clientId =  process.env.REACT_APP_CLIENT_ID || "2fdf1d3a-c504-4c8b-ac18-0d4d819b5baa";
const tenantId = process.env.REACT_APP_TENANT_ID || "dbcf11a5-dc7f-43e0-b1af-818a397917c7";
const msalConfig = {
    auth: {
        clientId: clientId,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri: process.env.REACT_APP_REDIRECT_URL,
    },
};

const msalInstance = new PublicClientApplication(msalConfig);

export { msalInstance, msalConfig };