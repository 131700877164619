import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { useLoading } from "../shared/LoadingContext";
import { toast } from "react-toastify";

const Configure = () => {
  const { instance, accounts } = useMsal();

  const [autoScaleDetails, setAutoScaleDetails] = useState([]);
  const { loading, setLoading } = useLoading();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
  const apiScope = "api://utilities/managefunctionapp";

  // Form State
  const [downscaleTime, setDownscaleTime] = useState("");
  const [upscaleTime, setUpscaleTime] = useState("");
  const [downscaleEDTU, setDownscaleEDTU] = useState(0);
  const [upscaleEDTU, setUpscaleEDTU] = useState(0);
  const [perDatabaseEDTU, setPerDatabaseEDTU] = useState(0);
  const [isAutoScaleEnabled, setIsAutoScaleEnabled] = useState(false);
  const [edtuUnits, setEdtuUnits] = useState(0);

  const fetchData = async () => {
    try {
      const accessToken = await instance.acquireTokenSilent({
        scopes: [apiScope],
        account: accounts[0],
      });
      setLoading(true);

      const response = await axios.get(`${apiUrl}/accelerus/configure`, {
        headers: {
          Authorization: `Bearer ${accessToken.accessToken}`,
        },
      });

      setAutoScaleDetails(response.data as []);
      setDownscaleTime(response.data.scaleDownTime);
      setDownscaleEDTU(response.data.downScaleEDTU);
      setUpscaleTime(response.data.scaleUpTime);
      setUpscaleEDTU(response.data.upScaleEDTU);
      setPerDatabaseEDTU(response.data.perDatabaseEDTU);
      setIsAutoScaleEnabled(response.data.isActive);
    } catch (ex) {
      toast.error("Error Occurred!!");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveSettings = async () => {
    try {
      setLoading(true);
      const accessToken = await instance.acquireTokenSilent({
        scopes: [apiScope],
        account: accounts[0],
      });
      const response = await axios.put(
        `${apiUrl}/accelerus/configure/settings`,
        {
          scaleDownTime: downscaleTime,
          scaleUpTime: upscaleTime,
          downScaleEDTU: downscaleEDTU,
          upScaleEDTU: upscaleEDTU,
          perDatabaseEDTU: perDatabaseEDTU,
          isActive: isAutoScaleEnabled,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken.accessToken}`,
          },
        }
      );
      toast.success("Settings saved successfully!");
    } catch (error) {
      toast.error("Failed to save settings");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [instance, accounts]);

  return (
    <div>
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-lg font-bold text-start">Configure Compute Tier</h2>
      </div>
      <div className="border-b border-gray-200 w-full mt-2"></div>

      <div className="w-full mx-auto mt-4 bg-white rounded-xl shadow-md overflow-hidden p-6">
      <h2 className="text-md font-bold text-gray-700 mb-2">Configure Scheduled Compute Tier</h2>
        <hr className="my-4 border-gray-200" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
              <label className="font-bold">Resource Group : </label>
              <label>{(autoScaleDetails as any)?.resourceGroup}</label>
          </div>
          <div>
              <label className="font-bold">Subscription Id : </label>
              <label>{(autoScaleDetails as any)?.subscriptionId }</label>
          </div>
          <div>
              <label className="font-bold">Server : </label>
              <label>{(autoScaleDetails as any)?.sqlServerName}</label>
          </div>
          <div>
              <label className="font-bold">Elastic Pool Name : </label>
              <label>{(autoScaleDetails as any)?.elasticPoolName }</label>
          </div>
          <hr className="col-span-2 my-4 border-gray-200" />
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Downscale Time
            </label>
            <input
              type="time"
              value={downscaleTime}
              onChange={(e) => setDownscaleTime(e.target.value)}
              className="mt-1 block w-full p-2 border rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Downscale eDTU Units
            </label>
            <input
                required
                type="range"
                min="100"
                max="3000"
                step="100"
                value={downscaleEDTU}
                onChange={(e) => setDownscaleEDTU(parseInt(e.target.value))}
                className="w-full"
                />
                <span> {downscaleEDTU}</span>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Upscale Time
            </label>
            <input
              type="time"
              value={upscaleTime}
              onChange={(e) => setUpscaleTime(e.target.value)}
              className="mt-1 block w-full p-2 border rounded-md"
            />
             
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Upscale eDTU Units
            </label>
            <input
                required
                type="range"
                min="100"
                max="3000"
                step="100"
                value={upscaleEDTU}
                onChange={(e) => setUpscaleEDTU(parseInt(e.target.value))}
                className="w-full"
                />
                <span> {upscaleEDTU}</span>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Per Database eDTU Units
            </label>
            <input
                required
                type="range"
                min="50"
                max="1000"
                step="50"
                value={perDatabaseEDTU}
                onChange={(e) => setPerDatabaseEDTU(parseInt(e.target.value))}
                className="w-full"
                />
                <span> {perDatabaseEDTU}</span>
          </div>
          <div className="flex items-center mt-4">
            <input
              type="checkbox"
              checked={isAutoScaleEnabled}
              onChange={() => setIsAutoScaleEnabled(!isAutoScaleEnabled)}
              className="form-checkbox mr-2"
            />
            <label className="text-sm font-medium text-gray-700">
              Enable Schedule
            </label>
          </div>
        </div>

        <button
          onClick={handleSaveSettings}
          className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Update Settings
        </button>
      </div>

      {/* eDTU Units Slider */}
      <div className="w-full mx-auto mt-8 bg-white rounded-xl shadow-md overflow-hidden p-6">
        <h2 className="text-md font-bold text-gray-700 mb-2">Real Time Compute Tier</h2>
        <hr className="my-4 border-gray-200" />
        
        <label className="block text-sm font-medium text-gray-700">
              eDTU Capacity
        </label>
        <input
          type="range"
          min="50"
          max="3000"
          step="100"
          value={edtuUnits}
          onChange={(e) => setEdtuUnits(parseInt(e.target.value))}
          className="w-full"
        />
        <p className="text-center mt-2">Selected eDTU Units: {edtuUnits}</p>
        <button
          className="mt-4 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
        >
          Update eDTU Units
        </button>
      </div>
    </div>
  );
};

export default Configure;