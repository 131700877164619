import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  ClockIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";


const Migration = () => {
  const { application } = useParams();
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  const [migrations, setMigrations] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiScope = "api://utilities/managefunctionapp";

  const fetchData = async () => {
    try {
      const accessToken = await instance.acquireTokenSilent({
        scopes: [apiScope],
        account: accounts[0],
      });

      const response = await axios.get(
        `${apiUrl}/migration/list/${application}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.accessToken}`,
          },
        }
      );

      setMigrations(response.data.migrations as []);
    } catch (ex) {}
  };

  const redirectToDetails = (migrationId: string) => {
    navigate(`/${application}/migration/detail?migrationId=${migrationId}`);
  };

  useEffect(() => {
    fetchData();
  }, [instance, accounts]);

  return (
    <div>
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-lg font-bold text-start">
          {application} | Migrations
        </h2>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => {
            navigate(`/${application}/migration/`);
          }}
        >
          Create
        </button>
      </div>
      <div className="border-b border-gray-200 w-full mt-2"></div>

      <div className="migration-list">
        <div className="w-full mx-auto mt-4 bg-white rounded-xl shadow-md overflow-hidden">
          <ul className="divide-y divide-gray-200">
            {migrations?.map((data: any) => (
              <MigrationItem
                data={data}
                key={data.rowKey}
                redirectToDetails={redirectToDetails}
              ></MigrationItem>
            ))}
            { migrations?.length == 0 &&
              <li className="group/item relative flex items-center justify-between p-4">
                <span className="text-center w-full text-slate-400">No record found!</span>
              </li>
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

const MigrationItem = ({
  data,
  redirectToDetails,
}: {
  data: any;
  redirectToDetails: (migrationId: string) => void;
}) => {
  return (
    <li
      key={data.rowKey}
      className="group/item relative flex items-center justify-between p-4 hover:bg-slate-100 cursor-pointer"
      onClick={() => {
        redirectToDetails(data.rowKey);
      }}
    >
      <div className="flex gap-4">
        {data.status == 4 && (
          <CheckCircleIcon className="h-7 w-7 text-green-500  mr-3"></CheckCircleIcon>
        )}
        {[0, 1].includes(data.status) && (
          <ClockIcon className="h-7 w-7 text-gray-500  mr-3"></ClockIcon>
        )}
        {data.status == 3 && (
          <XCircleIcon className="h-7 w-7 text-red-500  mr-3"></XCircleIcon>
        )}
        {data.status == 2 && (
          <svg
            className="animate-spin mr-3 h-7 w-7 text-green-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        <div className="ml-3 mr-4">
          <p className="text-sm text-start font-medium text-gray-900">
            {data.partitionKey}
          </p>
          <p className="text-sm text-gray-500">
            {moment(data.createdAt).format("MMMM Do, YYYY, h:mm:ss A")}
          </p>
        </div>

        <div className="ml-3">
          <p className="text-sm text-start font-medium text-gray-900">
            #{data.rowKey}
          </p>
          <p className="text-sm text-gray-500">
            <span className="mr-2">InsanceId : {data.instanceId}</span>
            SchoolCode: {data.schoolCode}
          </p>
        </div>
      </div>
      <a
        href="#"
        className="group/edit invisible relative flex items-center whitespace-nowrap rounded-full py-1 pl-4 pr-3 text-sm text-slate-500 transition hover:bg-slate-200 group-hover/item:visible"
      >
        <span className="font-semibold transition group-hover/edit:text-gray-700">
          View
        </span>
        <ChevronRightIcon className="h-5 w- text-gray-700"></ChevronRightIcon>
      </a>
    </li>
  );
};

export default Migration;
