import { useMsal } from "@azure/msal-react";
import { AppConstants } from "../constants";
import React, { FormEvent, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import { ListBulletIcon } from "@heroicons/react/24/outline";
import { useLoading } from "./shared/LoadingContext";
import { toast } from "react-toastify";

type migrationForm = {
  sourceUserName?: string,
  sourceIpAddress?: string,
  sourcePassword?: string,
  instanceId: string;
  server?: string;
  schoolCode?: string;
};

const Migration = () => {
  const server = ["A", "B", "C", "D", "E"];
  const { application } = useParams();
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<any>(null);
  const { loading, setLoading } = useLoading();
  const [migrationData, setMigrationData] = useState<migrationForm>({
    instanceId: "",
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const createMigration = async (event: FormEvent) => {
    event.preventDefault();
    if (!migrationData) {
      return;
    }

    //validate 
    if(!migrationData.instanceId || !migrationData.schoolCode || !migrationData.server){
      toast.error(`Invalid Check Migration details!!`);
      return;
    }

    try {
      setLoading(true);
      const accessToken = await instance.acquireTokenSilent({
        scopes: [AppConstants.apiScopes],
        account: accounts[0],
      });

      const response = await axios.post(
        `${apiUrl}/migration/${application}`,
        {
          sourceIpAddress: migrationData.sourceIpAddress,
          sourcePassword: migrationData.sourcePassword,
          sourceUserName: migrationData.sourceUserName,
          instanceId: migrationData.instanceId,
          server: migrationData.server,
          schoolCode: migrationData.schoolCode,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken.accessToken}`,
          },
        }
      );
      toast.success(`New Migration for ${application} created successfully!`);
      setMigrationData({
        instanceId: "",
        schoolCode: "",
        server: "",
      });
      (document.getElementById("migrationForm") as HTMLFormElement).reset();
    } catch (error) {
      toast.error(`Error Occured!`);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: any)=> {
    setMigrationData({
      ...migrationData, 
      [e.target.name]: e.target.value 
    });
  };

  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-lg font-bold text-start">
          {application} | Create Migration
        </h2>
        <button
          className="flex items-center justify-between bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => {
            navigate(`/migration/list/${application}`);
          }}
        >
          <ListBulletIcon className="h-5 w-5 text-white"></ListBulletIcon>
          List
        </button>
      </div>
      <div className="border-b border-gray-200 w-full mt-2"></div>

      <form
        onSubmit={createMigration}
        id="migrationForm"
        className="max-w-xl mt-4 p-6 bg-white shadow-md rounded-lg"
      >
        <h2 className="text-2xl font-bold mb-4">Migration</h2>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <div className="mb-4">
          <label
            htmlFor="instance"
            className="block text-sm font-medium text-gray-700 text-left"
          >
            Source IP Address
          </label>
          <div className="mt-2">
            <input
              type="text"
              id="sourceIpAddress"
              name="sourceIpAddress"
              onChange={handleChange }
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="mb-4 flex">
          <div className="flex-1 mr-2">
            <label
              htmlFor="instance"
              className="block text-sm font-medium text-gray-700 text-left"
            >
              Source Username
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="sourceUserName"
                name="sourceUserName"
                onChange={handleChange }
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="flex-1 ml-2">
            <label
              htmlFor="instance"
              className="block text-sm font-medium text-gray-700 text-left"
            >
              Source Password
            </label>
            <div className="mt-2">
              <input
                type="password"
                id="sourcePassword"
                name="sourcePassword"
                onChange={handleChange }
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="schoolCode"
            className="block text-sm font-medium text-gray-700 text-left"
          >
            School Code
          </label>
          <div className="mt-2">
            <input
              type="text"
              id="schoolCode"
              name="schoolCode"
              onChange={handleChange }
              required
              minLength={6}
              maxLength={6}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="instance"
            className="block text-sm font-medium text-gray-700 text-left"
          >
            Xuno InstanceId
          </label>
          <div className="mt-2">
            <input
              type="text"
              id="instanceId"
              name="instanceId"
              onChange={handleChange }
              required
              minLength={6}
              maxLength={6}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        {application === "GradeXpert" && (
          <div className="mb-4">
            <label
              htmlFor="server"
              className="block text-sm font-medium text-gray-700 text-left"
            >
             Target XUNO Mysql Server
            </label>
            <div className="mt-2">
              <select
                id="server"
                name="server"
                onChange={handleChange }
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select a server</option>
                {server.map((value, index) => {
                  return (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        )}
        <button 
          type="submit"
        
          className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          disabled={loading}
        >
          {loading ? "Loading..." : "Start Migration"}
        </button>
        <br />
        {message && (
          <div id="message" className="mt-4">
            {message}
          </div>
        )}
        <br />
        <a id="link" href="" className="text-blue-500 underline"></a>
      </form>
    </>
  );
};

export default Migration;
