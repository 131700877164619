import React, { ReactNode, useState } from "react";
import Sidebar from "./Sidebar";
import { ToastContainer } from "react-toastify";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 flex flex-col h-screen">{children}</div>
      <ToastContainer />
    </div>
  );
};

export default Layout;