import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { toast } from "react-toastify";
import { useLoading } from "./shared/LoadingContext";

const MigrationDetails = () => {
  const { application } = useParams();
  var query = new URLSearchParams(useLocation().search);
  var migrationId = query.get("migrationId");
  const [logs, setLogs] = useState<any>();

  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  const [migration, setMigration] = useState<any>();
  const { loading, setLoading } = useLoading();
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiScope = "api://utilities/managefunctionapp";

  const fetchData = async () => {
    try {
      setLoading(true);
      const accessToken = await instance.acquireTokenSilent({
        scopes: [apiScope],
        account: accounts[0],
      });

      const response = await axios.get(
        `${apiUrl}/migration/${application}?migrationId=${migrationId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.accessToken}`,
          },
        }
      );
      if (response.data.success) {
        setMigration(response.data.data);
      }
    } catch (ex) {
    } finally {
      setLoading(false);
    }
  };

  const fetchLogs = async (taskId: string) => {
    try {
      setLoading(true);
      const accessToken = await instance.acquireTokenSilent({
        scopes: [apiScope],
        account: accounts[0],
      });

      const response = await axios.get(
        `${apiUrl}/migration/logs/${application}?migrationId=${migrationId}&taskId=${taskId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.accessToken}`,
          },
        }
      );
      setLogs(response.data);
    } catch (ex) {
      toast.error("Error Occured!!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [instance, accounts]);

  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-lg font-bold text-start">
          {application}| {migrationId} | Migrations
          <span className="block text-sm font-normal text-gray-500">
            InstanceId: {migration?.instanceId} | SchoolCode:{" "}
            {migration?.schoolCode}
          </span>
        </h2>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => {
            navigate(`/${application}/migration/list/`);
          }}
        >
          List
        </button>
      </div>
      <div className="border-b border-gray-200 w-full mt-2"></div>

      <div className="migration-list w-full h-5/6 flex mx-auto mt-4 bg-white rounded-xl">
        <div className="tasks w-1/3 border-r-8 border-gray-500 flex flex-col flex-grow">
          <ul className="divide-y divide-gray-200">
            {migration?.tasks?.map((data: any) => (
              <MigrationStep
                data={data}
                key={data.id}
                loadLogs={fetchLogs}
              ></MigrationStep>
            ))}
          </ul>
        </div>
        <div className="logs w-2/3  bg-black text-white flex-grow font-mono p-2 justify-start overflow-y-auto">
          {logs?.map((log: any) => (
            <div key={log.Timestamp}>
              <span className="mr-2">{">"}</span>
              <span className="mr-2">
                {moment(log.Timestamp).format("MMMM Do, YYYY, h:mm:ss A")}
              </span>
              <span className="mr-2 text-blue-400">{log.Level}</span>
              <span className="whitespace-pre-wrap break-all">
                {log.Message}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const MigrationStep = ({
  data,
  loadLogs,
}: {
  data: any;
  loadLogs: (taskId: string) => {};
}) => {
  return (
    <li
      key={data.id}
      className="group/item relative flex items-center justify-between p-4 hover:bg-slate-100 cursor-pointer"
      onClick={() =>
        (data.status == 4 || data.status == 3) && loadLogs(data.id)
      }
    >
      <div className="flex gap-4">
        {data.status == 4 && (
          <CheckCircleIcon className="h-7 w-7 text-green-500  mr-3"></CheckCircleIcon>
        )}
        {[0, 1].includes(data.status) && (
          <ClockIcon className="h-7 w-7 text-gray-500  mr-3"></ClockIcon>
        )}
        {data.status == 3 && (
          <XCircleIcon className="h-7 w-7 text-red-500  mr-3"></XCircleIcon>
        )}
        {data.status == 2 && (
          <svg
            className="animate-spin mr-3 h-7 w-7 text-green-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        <div className="ml-3 mr-4 text-start">
          <p className="text-sm font-medium text-gray-900">{data.name}</p>
          <p className="text-sm text-gray-500">
            {moment(data.timestamp).format("MMMM Do, YYYY, h:mm:ss A")}
          </p>
        </div>
      </div>
    </li>
  );
};

export default MigrationDetails;
